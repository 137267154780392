<div class="w-100 d-flex">
  <img
    width="66px"
    height="80.76px"
    src="../../../../../../assets/img/logo.png"
    class="logo"
  />
  <div class="d-flex align-items-center w-100">
    <nav class="navbar navbar-expand-lg w-100">

      <div class="container-fluid">
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse w-100" id="navbarSupportedContent">
          <ul
            class="navbar-nav justify-content-md-start gap-4 mb-2 m-lg-0 p-0 w-100 mt-4 align-items-center"
          >
            <li
              class="nav-item"
              *ngFor="let nav of navs"
              [class]="{ dropdown: nav?.hasChildren }"
            >
              <a
                class="nav-link p-0"
                aria-current="page"
                (click)="nav.name == 'services' ? goToComponent() : '' "
                [routerLink]="[nav?.link]"
                [routerLinkActive]="nav?.toBlock ? '' : 'router-link-active'"
                *ngIf="!nav?.hasChildren"
                >{{ "general.header."+nav?.name | translate}}</a>
              <a
                class="nav-link dropdown-toggle"
                *ngIf="nav?.hasChildren"
                id="navbarDropdown1"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {{ "general.header."+nav?.name | translate}}
              </a>
              <ul class="dropdown-menu" *ngIf="nav?.hasChildren">
                <li *ngFor="let menu of nav?.subMenus" class="submenu">
                  @if (!menu?.param) {
                    <a class="dropdown-item" routerLinkActive="router-link-active" [routerLink]="menu?.link"
                    >{{ "general.header."+menu?.name | translate }}
                  </a>
                }
                @else if (menu?.param) {
                  <a class="dropdown-item" (click)="onclickRoute(menu?.link, menu?.param)">
                    {{ "general.header."+menu?.name | translate }}
                  </a>
                }
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</div>
