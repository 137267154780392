<div class="container">
  <div class="user-translate-wrapper">
    <div class="d-flex align-items-center">
      <div class="drop-wrap">
        <a class="btn d-flex border-0" href="#" id="navbarDropdown2" role="button" data-bs-toggle="dropdown"
          aria-expanded="false">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
            <path
              d="M11.9998 15.1316C11.8078 15.1316 11.6157 15.0586 11.4697 14.9116L7.46975 10.9116C7.17675 10.6186 7.17675 10.1436 7.46975 9.85061C7.76275 9.55761 8.23779 9.55761 8.53079 9.85061L12.0008 13.3206L15.4707 9.85061C15.7637 9.55761 16.2388 9.55761 16.5318 9.85061C16.8248 10.1436 16.8248 10.6186 16.5318 10.9116L12.5318 14.9116C12.3838 15.0586 12.1918 15.1316 11.9998 15.1316Z"
              fill="black" />
          </svg>

          <svg *ngIf="currentLanguage == 'ar'" xmlns="http://www.w3.org/2000/svg" width="28" height="21"
            viewBox="0 0 28 21" fill="none">
            <g clip-path="url(#clip0_5540_503)">
              <path
                d="M25.3333 0.381592H2.66667C1.19391 0.381592 0 1.5755 0 3.04826V17.7149C0 19.1877 1.19391 20.3816 2.66667 20.3816H25.3333C26.8061 20.3816 28 19.1877 28 17.7149V3.04826C28 1.5755 26.8061 0.381592 25.3333 0.381592Z"
                fill="white" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M0 13.7148H28V20.3815H0V13.7148Z" fill="#151515" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0.381592H28V7.04826H0V0.381592Z" fill="#249F58" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0.381592V20.3816H8V0.381592H0Z" fill="#F93939" />
            </g>
            <defs>
              <clipPath id="clip0_5540_503">
                <rect y="0.381592" width="28" height="20" rx="3" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <svg *ngIf="currentLanguage == 'en'" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink" width="28" height="25" viewBox="0 0 36 36" aria-hidden="true"
            role="img" class="iconify iconify--twemoji" preserveAspectRatio="xMidYMid meet">
            <path fill="#00247D"
              d="M0 9.059V13h5.628zM4.664 31H13v-5.837zM23 25.164V31h8.335zM0 23v3.941L5.63 23zM31.337 5H23v5.837zM36 26.942V23h-5.631zM36 13V9.059L30.371 13zM13 5H4.664L13 10.837z" />
            <path fill="#CF1B2B"
              d="M25.14 23l9.712 6.801a3.977 3.977 0 0 0 .99-1.749L28.627 23H25.14zM13 23h-2.141l-9.711 6.8c.521.53 1.189.909 1.938 1.085L13 23.943V23zm10-10h2.141l9.711-6.8a3.988 3.988 0 0 0-1.937-1.085L23 12.057V13zm-12.141 0L1.148 6.2a3.994 3.994 0 0 0-.991 1.749L7.372 13h3.487z" />
            <path fill="#EEE"
              d="M36 21H21v10h2v-5.836L31.335 31H32a3.99 3.99 0 0 0 2.852-1.199L25.14 23h3.487l7.215 5.052c.093-.337.158-.686.158-1.052v-.058L30.369 23H36v-2zM0 21v2h5.63L0 26.941V27c0 1.091.439 2.078 1.148 2.8l9.711-6.8H13v.943l-9.914 6.941c.294.07.598.116.914.116h.664L13 25.163V31h2V21H0zM36 9a3.983 3.983 0 0 0-1.148-2.8L25.141 13H23v-.943l9.915-6.942A4.001 4.001 0 0 0 32 5h-.663L23 10.837V5h-2v10h15v-2h-5.629L36 9.059V9zM13 5v5.837L4.664 5H4a3.985 3.985 0 0 0-2.852 1.2l9.711 6.8H7.372L.157 7.949A3.968 3.968 0 0 0 0 9v.059L5.628 13H0v2h15V5h-2z" />
            <path fill="#CF1B2B" d="M21 15V5h-6v10H0v6h15v10h6V21h15v-6z" />
          </svg>
        </a>
        <ul class="dropdown-menu">
          <li *ngIf="currentLanguage == 'ar'" (click)="selectLanguage(LangConfig.EN)">
            <div class="dropdown-item">EN</div>
          </li>
          <li *ngIf="currentLanguage == 'en'" (click)="selectLanguage(LangConfig.AR)">
            <div class="dropdown-item">AR</div>
          </li>
        </ul>
      </div>

      <div class="d-flex">
        <!-- <button class="btn">
          <img src="../../../../../../assets/img/header-images/accessability.svg" />
        </button> -->

        <div class="drop-wrap" *ngIf="
            (authService.isAuthenticated$ | async);
            else registerIcon
          ">
          <a class="nav-link dropdown-toggle btn user-btn" href="#" id="navbarDropdown1" role="button"
            data-bs-toggle="dropdown" aria-expanded="false">
            <img src="../../../../../../assets/img/header-images/avatar-user.svg" />
            <i class="fa-solid fa-chevron-down"></i>
          </a>
          <ul class="dropdown-menu">
            <li>
              <a class="dropdown-item" [routerLink]="['/profile']">{{'general.header.profile' | translate}}</a>
            </li>
            <li *ngIf="hasEdit">
              <a *ngIf="getToken()" class="dropdown-item cursor-pointer" (click)="authService.toggleEditMode()">
                {{inEditMode? ('general.header.editContentClose' | translate): ('general.header.editContentOpen' | translate)}}
              </a>
            </li> 
            <li>
              <a class="dropdown-item" (click)="logOut()">{{'general.header.logout' | translate}}</a>
            </li>
          </ul>
        </div>

        <ng-template #registerIcon>
          <a [routerLink]="['/auth/sign-in']" class="btn" pTooltip="{{'general.header.login' | translate}}"
            tooltipPosition="bottom">
            <img src="../../../../../../assets/img/header-images/avatar-user.svg" />
          </a>
        </ng-template>
      </div>
    </div>
  </div>
</div>
