<header [class.grey-bg]="isDarkBg">
  <div class="container mb-0 pb-5">
    <div class="d-flex justify-content-between py-4">
      <div class="main-wrapper mob-v w-100">
        <app-nav class="w-100"></app-nav>
      </div>
      <div class="menu-logo-wrapper align-items-center">
        <app-user></app-user>
      </div>
    </div>
  </div>
</header>
